<template>
  <section class="role-list-wrap">
    <section class="content">
      <div class="btn-wrap">
<!--        <el-button size="small" type="primary" @click="addTag">创建</el-button>-->
      </div>
      <el-table
          :data="tableData"
          border v-loading="loading"
          style="width: 100%">
          <el-table-column  :resizable="false"
            prop="name"
            label="角色"
            align="center"
            width="120">
          </el-table-column>
          <el-table-column  :resizable="false"
            prop="menunames"
            label="拥有权限"
            align="center">
            <template scope="{ row }">
              <span v-for="(item, index) in row.menunames" :key="index">
                {{ item.menuname }}
              </span>
            </template>
          </el-table-column>
          <el-table-column  :resizable="false"
            align="center"
            label="操作"
            width="80">
            <template scope="{ row }">
              <el-button type="text" @click="editPermission(row.id)">编辑</el-button>
            </template>
          </el-table-column>
      </el-table>
      <section class="list-bottom">
          <span class="count">共{{count}}条记录</span>
          <el-pagination
            :current-page="1"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count">
          </el-pagination>
        </section>
    </section>
  </section>
</template>

<script>
import { selectAllRole } from '@/api/settings/role'
import eventHub from '@/utils/eventHub'
export default {
  methods: {
    editPermission (id) {
      // this.$router.push('/settings/permission')
      this.$router.push({
        name: 'permission',
        params: {
          id: id,
          c: '0'
        }
      })
    },
    async listAll () {
	    this.loading = true
      const res = await selectAllRole()
      this.tableData = res.data.list
      this.count = res.data.total
      this.loading = false
    }
  },
  mounted () {
    this.listAll()
    eventHub.$emit('msg', '角色管理')
  },
  data () {
    return ({
      form: {
        lastTime: '',
        keyWords: ''
      },
	  loading:false,
      tableData: [
        {
          name: '',
          menunames: [
            {
              menuname: ''
            }
          ]
        }
      ],
      count: 0
    })
  }
}
</script>

<style lang="scss" scoped="">
.role-list-wrap {
  width: 100%;
  height: 100%;
  .filter {
    width: 100%;
    height: 80px;
    padding: 20px;
    background: #fff;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius:2px;
    margin-bottom: 30px;
    .btn-item {
      margin-left: 25px;
    }
  }
  .content {
    width: 100%;
    min-height: calc(100% - 110px);
    background: #fff;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius:10px;
    padding: 20px;
    position: relative;
    .btn-wrap {
      margin-bottom: 25px;
    }
    .el-table {
      margin-bottom: 50px;
      .el-button--text {
        padding: 0;
      }
    }
    .list-bottom {
      display: flex;
      justify-content: space-between;
      /* position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px; */
      .count {
        line-height: 32px;
      }
    }
  }
}
</style>
